import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { ThemeProvider } from '@adc-polaris-component-library/component-library';

import { useRouter, useTheme } from 'Hooks';

import Modal from 'Components/utility/Modal/GeneralModal';

import { RootState } from 'src/reducers';

const mapState = ({ nav: { location, initErrorStatus, queryString } }: RootState) => {
  return {
    initErrorStatus,
    queryString,
    location,
  };
};

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const App: React.FC<Props> = ({ initErrorStatus, queryString, location }) => {
  const { theme } = useTheme();
  const { Component, props } = useRouter(location, queryString, initErrorStatus);

  return (
    <ThemeProvider app={theme}>
      <Component {...props} />
      <Modal />
    </ThemeProvider>
  );
};

export default connector(App);
