import React, { ReactNode, memo, useCallback, useRef } from 'react';

import { IInputProps } from 'native-base';

import { Input } from 'Components/utility/Input';

import ImageButton from '../ImageButton';

type DateInputProps = {
  isInvalid?: boolean;
  label: string;
  errorMessage?: string;
  placeholder?: string;
  leftElement?: ReactNode;
  setFieldTouched: any;
  onClick: () => void;
};

type Props = DateInputProps & IInputProps;

type ArrowIconElementProps = {
  onPress: () => void;
  setFieldTouched: any;
  nativeID: string;
};

const ArrowIconElement: React.FC<ArrowIconElementProps> = memo(
  ({ onPress, setFieldTouched, nativeID }) => {
    const onPressArrow = useCallback(() => {
      onPress();
      setFieldTouched(nativeID);
    }, [nativeID, onPress, setFieldTouched]);

    return (
      <ImageButton
        nativeID="RegisterPersonalInfo.arrowDateOfBirth"
        imageUriKey="CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.icons.chevronRight"
        tintColor="interactive.text.active"
        onPress={onPressArrow}
        pressableProps={{
          paddingRight: 5,
          paddingTop: 4,
          paddingBottom: 4,
        }}
        imageProps={{
          size: 3,
          width: 2,
        }}
      />
    );
  }
);

ArrowIconElement.displayName = 'ArrowIconElement';

const DateInput: React.FC<Props> = ({
  isInvalid = false,
  label = '',
  errorMessage,
  placeholder = '',
  nativeID = 'dateOfBirth',
  value,
  setFieldTouched,
  onClick,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const onEventStart = useCallback(() => {
    setFieldTouched(nativeID);
    onClick();

    //  Hack to force the keyboard to close on iOS
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.blur();
    }
  }, [nativeID, onClick, setFieldTouched]);

  return (
    <Input
      ref={inputRef}
      placeholder={placeholder}
      label={label}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      keyboardType="default"
      nativeID={nativeID}
      editable={false}
      focusable={false}
      value={value}
      onTouchEnd={onEventStart}
      InputRightElement={
        <ArrowIconElement
          onPress={onEventStart}
          setFieldTouched={setFieldTouched}
          nativeID={nativeID}
        />
      }
      {...rest}
    />
  );
};

export default memo(DateInput);
