import styled, { css } from 'styled-components';

type ContainerProps = {
  textActive?: string;
  textTitle?: string;
  backgroundColor?: string;
  neutral50?: string;
  text100?: string;
  fontSizeXL?: string;
  fontSizeBodyBaseDefault?: string;
  fontWeightBold?: string;
  fontWeightRegular?: string;
  fontWeightSemiBold?: string;
};

export const Container = styled.div<ContainerProps>`
  ${(props) => css`
    .select-wrap {
      position: relative;
      top: 10%;
      height: 70%;
      text-align: center;

      overflow: hidden;
      font-size: ${props.fontSizeXL};
      color: #ddd;

      &:before,
      &:after {
        position: absolute;
        z-index: 1;
        display: block;
        content: '';
        width: 100%;
        height: 50%;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }

      .select-options {
        position: absolute;
        top: 50%;
        list-style-type: none;
        text-align: center;
        width: 100%;
        height: 0;
        transform-style: preserve-3d;
        margin: 0 auto;
        display: block;
        transform: translateZ(-150px) rotateX(0deg);
        -webkit-font-smoothing: subpixel-antialiased;
        color: ${props.neutral50};
        font-family: ${props.fontWeightRegular};

        .select-option {
          position: absolute;
          top: 0;
          height: 50px;
          box-sizing: border-box;

          -webkit-font-smoothing: subpixel-antialiased;
          @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
              transform: rotateX(-18deg * ($i - 1)) translateZ(150px);
            }
          }
        }
      }
    }

    .selector {
      position: absolute;
      background-color: transparent;
      border: 1px solid ${props.textTitle};
      box-shadow: 0px 0px 10px rgb(0 0 0 / 0.5);
      border-radius: 8px;
      height: 40px;
      width: 80%;
      margin-left: 40px;
      display: inline;
      top: 45%;
      transform: translate(0, -50%);
      z-index: 1;
      pointer-events: none;
    }

    .highlight {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      background-color: ${props.backgroundColor};
      color: ${props.text100};
      overflow: hidden;
    }

    .highlight-list {
      position: absolute;
      list-style-type: none;
      top: -150%;
      font-family: ${props.fontWeightSemiBold};
      font-size: ${props.fontSizeXL};
    }

    .date-selector-container {
      position: absolute;
      z-index: 2;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 100%;
      height: 20rem;
      display: flex;
      flex-direction: column;
      background-color: ${props.backgroundColor};
      bottom: 0px;
    }

    .date-selector-header {
      display: flex;
      flex: 0.1;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 10px;
      margin-left: 4px;
      margin-right: 5px;
      height: 100px;

      .btn-save {
        background-color: transparent;
        border: 0;
        color: ${props.textActive};
        font-size: ${props.fontSizeBodyBaseDefault};
        font-family: ${props.fontWeightSemiBold};
        height: 50px;
      }

      .btn-cancel {
        background-color: transparent;
        border: 0;
        color: ${props.textActive};
        font-size: ${props.fontSizeBodyBaseDefault};
        font-family: ${props.fontWeightRegular};
        height: 50px;
      }

      .title {
        color: ${props.textTitle};
        font-size: ${props.fontSizeBodyBaseDefault};
        font-family: ${props.fontWeightBold};
      }
    }

    .date-selector {
      position: relative;
      display: flex;
      flex-direction: row;
      flex: 1;

      .select-wrap {
        font-size: ${props.fontSizeBodyBaseDefault};
      }
      .highlight {
        font-size: ${props.fontSizeXL};
        font-weight: bold;
      }
    }

    .month {
      display: flex;
      flex-direction: column;
      flex: 1.2;

      .select-option {
        right: -20px;
        font-size: ${props.fontSizeBodyBaseDefault};
        text-align: center;
        width: 100%;
      }

      .highlight-list {
        text-align: center;
        right: 0;
      }
    }

    .day {
      display: flex;
      flex-direction: column;
      flex: 0.5;

      .select-option {
        right: 20px;
        font-size: ${props.fontSizeBodyBaseDefault};
        width: 100%;
        text-align: center;
      }

      .highlight-list {
        left: -40px;
        width: 100%;
      }
    }

    .year {
      display: flex;
      flex-direction: column;
      flex: 1;

      .select-option {
        left: 15px;
        font-size: ${props.fontSizeBodyBaseDefault};
      }

      .highlight-list {
        left: -32px;
      }
    }

    .spinner {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: ${props.backgroundColor};
      z-index: 2;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    @media all and (min-width: 200px) and (max-width: 300px) {
      .selector {
        width: 88%;
      }

      .month {
        .select-option {
          right: 0;
        }

        .highlight-list {
          right: 0;
        }
      }

      .selector {
        margin-left: 15px;
      }
    }

    @media all and (min-width: 300px) and (max-width: 320px) {
      .selector {
        width: 90%;
      }

      .month {
        .select-option {
          right: 0;
        }

        .highlight-list {
          right: 0;
        }
      }

      .selector {
        margin-left: 15px;
      }
    }

    @media all and (min-width: 500px) {
      .date-selector {
        width: 30em;
        align-self: center;
      }
      .selector {
        width: 92%;
      }

      .month {
        .select-option {
          right: -20px;
        }

        .highlight-list {
          right: 15px;
        }
      }

      .selector {
        margin-left: 15px;
      }
    }
  `}
`;
