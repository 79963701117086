import { useEffect, useState } from 'react';

import { detectTheme, getTheme } from 'Utilities/dataHelpers';

const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(getTheme());

  useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

    matchMedia.addEventListener('change', () => {
      const urlParams = new URLSearchParams(window.location.search);

      setTheme(detectTheme(urlParams));
    });
  }, []);

  return { theme };
};

export default useTheme;
