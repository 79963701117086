import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Text } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { Image } from 'native-base';
import * as Yup from 'yup';

import { ApiStatus, ErrorCode } from 'Enums';

import { useAccount, useApiError, useAuthError } from 'Hooks';

import { Button } from 'Components/utility/Button';
import Form from 'Components/utility/Form';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import InputPassword from 'Components/utility/InputPassword';
import Main from 'Components/utility/Main';
import PasswordInputValidator from 'Components/utility/PasswordInputValidator';
import ResponsiveContainer from 'Components/utility/ResponsiveContainer';
import Spinner from 'Components/utility/Spinner';

import i18n from 'Utilities/i18n';

import { resetPass } from 'Services/auth';

import { RootState } from 'src/reducers';

interface MyFormValues {
  newPassword: string;
  repeatNewPassword: string;
}

const initialValuesFormik: MyFormValues = {
  newPassword: '',
  repeatNewPassword: '',
};

const mapStateToProps = ({
  config: { validation },
  nav: {
    query: { email, token, product, version },
  },
}: RootState) => {
  return {
    validation,
    email,
    token,
    product,
    version,
    leadingTrailingSpacesIdentity: validation?.identity.find(
      (identity) => identity.data.key === 'leadingTrailingSpaces'
    ),
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & { next: () => void };

const ResetPasswordFirstStep: React.FC<Props> = ({
  validation,
  email,
  token,
  product,
  version,
  next,
  leadingTrailingSpacesIdentity,
}) => {
  const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

  const [loading, setLoading] = useState(false);

  const { showApiErrorModal, showNetworkErrorModal } = useApiError();

  const { showPasswordValidationError } = useAccount();

  const { showTokenExpirationError } = useAuthError();

  const validationSchema = Yup.object().shape({
    newPassword: leadingTrailingSpacesIdentity
      ? Yup.string().matches(
          new RegExp(leadingTrailingSpacesIdentity.data.regex),
          i18n.t<string>(
            `Login.content.signInForm.formField.password.helpText.${leadingTrailingSpacesIdentity.data.key}`
          )
        )
      : Yup.string(),
    repeatNewPassword: Yup.string()
      .required(
        i18n.t<string>(
          'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      )
      .oneOf(
        [Yup.ref('newPassword')],
        i18n.t<string>(
          'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      ),
  });

  const { handleSubmit, handleBlur, setFieldValue, isValid, errors, touched, values, setTouched } =
    useFormik({
      initialValues: initialValuesFormik,
      onSubmit: onSubmitForm,
      validationSchema,
      validateOnBlur: true,
    });

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.BAD_REQUEST: {
          const errors = error.response?.data.errors;

          setFieldValue('newPassword', '');
          setFieldValue('repeatNewPassword', '');

          setTouched({});

          showPasswordValidationError({ validation }, errors);
          break;
        }

        case ApiStatus.UNAUTHORIZED: {
          showTokenExpirationError();
          break;
        }

        default:
          error.code === ErrorCode.NETWORK_ERROR ? showNetworkErrorModal() : showApiErrorModal();
      }
    },
    [
      setFieldValue,
      setTouched,
      showApiErrorModal,
      showNetworkErrorModal,
      showPasswordValidationError,
      showTokenExpirationError,
      validation,
    ]
  );

  function onSubmitForm() {
    setLoading(true);
    resetPass(values.newPassword, token, product, version)
      .then(next)
      .catch(handleApiRequestError)
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Main>
      {loading && <Spinner />}
      <Image
        position={'absolute'}
        zIndex={1}
        resizeMode="contain"
        alt=""
        mt={5}
        width={220}
        height={50}
        alignSelf="center"
        nativeID="Global.microcopy.common.libreLogo"
        source={{
          uri: i18n.t<string>('Global.microcopy.common.libreLogo'),
        }}
      />
      <ResponsiveContainer pt={5}>
        <ContentHeader
          nativeIDTitle="ResetPassword.title"
          nativeIDSubtitle="ResetPassword.subtitle"
          title={i18n.t<string>('ResetPassword.title')}
          subtitle={i18n.t<string>('ResetPassword.subtitle')}
          textAlign="center"
          noActionBar
        />
        <Text
          nativeID="ResetPassword.title-email"
          color="text.80"
          fontSize="l"
          fontWeight="bodyBaseMedium"
          fontFamily="bodyBaseMedium"
          textAlign="center"
        >
          {email}
        </Text>
        <Form onSubmit={handleSubmit} style={{ padding: 0 }}>
          <PasswordInputValidator
            placeholder={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.newPassword.placeholder'
            )}
            label={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.newPassword.label'
            )}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            password={values.newPassword}
            onValidate={setMeetsPasswordRequirements}
            identities={validation?.identity}
            nativeID="newPassword"
          />
          <InputPassword
            mt={1}
            mb={5}
            label={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.label'
            )}
            placeholder={i18n.t<string>(
              'ResetPassword.content.resetPasswordForm.formField.repeatNewPassword.placeholder'
            )}
            isInvalid={errors.repeatNewPassword && touched.repeatNewPassword ? true : false}
            errorMessage={errors.repeatNewPassword}
            nativeID="repeatNewPassword"
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            value={values.repeatNewPassword}
          />
          <Button
            nativeID="ResetPassword.button"
            onPress={() => handleSubmit()}
            isDisabled={
              !values.newPassword ||
              !values.repeatNewPassword ||
              !isValid ||
              !meetsPasswordRequirements
            }
          >
            {i18n.t<string>('ResetPassword.title')}
          </Button>
        </Form>
      </ResponsiveContainer>
    </Main>
  );
};

export default connector(ResetPasswordFirstStep);
