import React from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Box, Heading, Image, VStack } from 'native-base';

import Main from 'Components/utility/Main';
import ResponsiveContainer from 'Components/utility/ResponsiveContainer';

import i18n from 'Utilities/i18n';

export default function ResetPasswordSecondStep() {
  // const [isDesktopScreen] = useMediaQuery({
  //   minWidth: 480,
  // });
  const isDesktopScreen = true;

  // const handleButtonClick = () => {
  //   // return window.open('');
  // };

  return (
    <Main>
      <Image
        position={'absolute'}
        zIndex={1}
        resizeMode="contain"
        alt=""
        mt={5}
        width={220}
        height={50}
        alignSelf="center"
        nativeID="Global.microcopy.common.libreLogo"
        source={{
          uri: i18n.t<string>('Global.microcopy.common.libreLogo'),
        }}
      />
      <ResponsiveContainer>
        <Image
          mt="10"
          alt="horizontal logo of free style libre"
          resizeMode="contain"
          nativeID="ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.media.passwordResetSuccess"
          height="105px"
          source={{
            uri: i18n.t<string>(
              'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.media.passwordResetSuccess'
            ),
          }}
        />
        <VStack
          mt="7"
          space="1"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Heading
            nativeID="ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.primaryText"
            fontWeight={'headlineBaseStrong'}
            fontFamily={'headlineBaseStrong'}
            fontSize="3xl"
            color="text.title"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t<string>(
              'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.primaryText'
            )}
          </Heading>
          <Text
            nativeID={
              isDesktopScreen
                ? 'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.secondaryText'
                : 'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessageApp.primaryText'
            }
            fontWeight="bodyBaseDefault"
            fontFamily="bodyBaseDefault"
            fontSize="l"
            color="text.80"
            display="flex"
            flexDirection="column"
            textAlign="center"
            w={isDesktopScreen ? '100%' : '80%'}
          >
            {i18n.t<string>(
              `${
                isDesktopScreen
                  ? 'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.secondaryText'
                  : 'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessageApp.primaryText'
              }`
            )}
          </Text>
        </VStack>
        <Box w="90%" mx="auto" mt="12">
          <Text
            nativeID="ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.bodyText"
            fontWeight="bodyBaseDefault"
            fontFamily="bodyBaseDefault"
            fontSize="l"
            color="text.80"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t<string>(
              'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.bodyText'
            )}
          </Text>

          {/*  : (
             <Button
               onPress={handleButtonClick}
               nativeID="ResetPasswordSuccessful.microcopy.ButtonTextSet.openApp"
             >
               {i18n.t<string>('ResetPasswordSuccessful.microcopy.ButtonTextSet.openApp')}
             </Button>
           )} */}
        </Box>
      </ResponsiveContainer>
    </Main>
  );
}
